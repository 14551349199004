<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Open <b>Odyssey</b>, go to the <b>Labs</b> tab, find the Acids & Bases section and click on
        <b>K1 Strong Acids.</b> Click on the plot icon () on the top tool bar. Click on +
        <b>Add Plot</b>, choose <b>XY Plot.</b> For the X-Axis, choose Distance. For the Y-Axis,
        choose Partial (Atomic) Charge. Click <b>Next</b> >, <b>Scatter Plot</b> and then
        <b>Finish.</b>
      </p>

      <p class="mb-4">
        With Hydroiodic Acid chosen, click &lt;select atom&gt; on the y axis and then click on the
        purple iodine atom. Then click on &lt;select 2 atoms&gt; on the x-axis and click on both the
        hydrogen and iodine atom. Click on the Record button.
      </p>

      <p class="mb-4">Repeat this process for hydrobromic and hydrochloric Acid.</p>

      <p class="mb-5">Which of the following statements are true? Select all that apply.</p>

      <p class="mb-2" v-for="question in questions" :key="question.inputName">
        <v-checkbox v-model="inputs.studentAnswers" :value="question.value" class="pl-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="question.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q8',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      questions: [
        {
          text: 'Acid strength increases with the charge of the anion connected to the acidic hydrogen',
          value: 'a',
          inputName: 'input1',
        },
        {
          text: 'The H-X bond length increases with acid strength.',
          value: 'b',
          inputName: 'input2',
        },
        {
          text: 'The partial atomic charge of the anion is inversely related to the H-X bond length',
          value: 'c',
          inputName: 'input3',
        },
        {
          text: 'The partial atomic charge measures the charge on the hydrogen atom',
          value: 'd',
          inputName: 'input4',
        },
        {text: 'Only HI is shown completely dissociated', value: 'e', inputName: 'input5'},
      ],
    };
  },
};
</script>
